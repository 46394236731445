import React from "react";
import styled from "styled-components";

const Text = styled.h1`
  position: fixed;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  color: #161616;
  opacity: 70%;
  font-size: calc(5em + 5vw);

  @media (max-width: 1024px) {
    font-size: calc(11.5em + 5vw);
    top: 6.5%;
    margin: 0 auto;
    left: -4.5%;
    }

  @media (max-width: 912px) {
    font-size: calc(10em + 5vw);
    top: 6.5%;
    margin: 0 auto;
    left: -4%;
    }

  @media (max-width: 820px) {
    font-size: calc(9em + 5vw);
    top: 9%;
    margin: 0 auto;
    left: -2.5%;
    }

  @media (max-width: 768px) {
    font-size: calc(8em + 5vw);
    top: 12%;
    margin: 0 auto;
    left: -1%;
    }
  @media (max-width: 768px) {
    font-size: calc(5.6em + 5vw);
    top: 12%;
    margin: 0 auto;
    left: -1%;
    }  

  @media (max-width: 430px) {
    font-size: calc(4.5em + 5vw);
    top: 17.5%;
    left: -1%;
    }

  @media (max-width: 414px) {
    font-size: calc(4.5em + 5vw);
    top: 18.5%;
    left: -3%;
    }
  @media (max-width: 390px) {
    font-size: calc(4.2em + 5vw);
    top: 20%;
    left: -2%;
    }
  
  @media (max-width: 375px) {
    font-size: calc(4em + 5vw);
    top: 26%;
    left: -2%;
    }
    
  @media (max-width: 360px) {
    font-size: calc(3.9em + 5vw);
    top: 23.5%;
    left: -2%;
    }
  
  @media (max-width: 280px) {
  font-size: calc(3em + 5vw);
  top: 28%;
  }
`;

const BigTitle = (props) => {
  return (
    <Text top={props.top} left={props.left} right={props.right}>
      {props.text}
    </Text>
  );
};

export default BigTitle;
