import React from "react";
import styled from "styled-components";
import LogoText from "../assets/Images/logotext.png";

const LogoLeft = styled.div`
  display: flex;
  top: 2rem;
  position: fixed;
  height: 4rem;

  @media (max-width: 768px) {
    height: 7rem;
    }

  @media (max-width: 540px) {
    height: 6rem;
    }

  @media (max-width: 430px) {
    height: 6rem;
    }

  @media (max-width: 414px) {
    height: 5rem;
    }
  
  @media (max-width: 360px) {
    height: 4rem;
    }  
  `;

const LogoComponent = (props) => {
  return  <LogoLeft className="lg:left-[2rem] left-[0.1rem]">
  <img className="logotext" src={LogoText} alt="logo text" />
  </LogoLeft> 
  };
export default LogoComponent;
