import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import SocialIcons from "../subComponents/SocialIcons";
import { LogoNanno } from "./AllSvgs";
import Intro from "./Intro";
import BG from "../assets/Images/bg.png";
import LogoText from "../assets/Images/logotext.png";

const MainContainer = styled.div`
  background: #070B00;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Karla", sans-serif;
    font-weight: 500;
    font-size: 1.3rem;
  }
`;

const Container = styled.div`
  padding: 2rem;
`;

const Img = styled.img`
  padding: 5px;
  transition: transform 0.2s;
`;

const Contact = styled(NavLink)`
  color: #ACEB2F;
  position: absolute;
  top: 42%;
  right: calc(-1.3rem + 2vw);
  transform: rotate(90deg) translate(-50%, -50%);
  text-decoration: none;
  z-index: 1;

  @media (max-width: 375px) {
    top: 44%;
    right: calc(-1.1rem + 2vw);
    }
  @media (max-width: 414px) {
    top: 43%;
    right: calc(-1.1rem + 2vw);
    }    
`;

const WORK = styled(NavLink)`
  color: #ACEB2F;
  position: absolute;
  top: 40%;
  left: calc(1rem + 2vw);
  transform: translate(-50%, -50%) rotate(-90deg);
  text-decoration: none;
  z-index: 1;
`;

const BottomBar = styled.div`
  position: absolute;
  bottom: 5rem;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const ABOUT = styled(NavLink)`
  color: #ACEB2F;
  text-decoration: none;
  z-index: 1;
`;

const SKILLS = styled(NavLink)`
  color: #ACEB2F;
  text-decoration: none;
  z-index: 1;
`;

const rotate = keyframes`
from {
    transform: rotate(0);
}
to {
    transform: rotate(360deg);
}
`;

const Center = styled.button`
  position: absolute;
  top: ${(props) => (props.click ? "80%" : "50%")};
  left: ${(props) => (props.click ? "92%" : "50%")};
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;

  @media (max-width: 420px) {
  transform: translate(-50%, -50%);
  top: ${(props) => (props.click ? "75%" : "50%")};
  left: ${(props) => (props.click ? "85%" : "50%")};
    }

  & > :first-child {
    animation: ${rotate} infinite 1.5s linear;
    
  }

  & > :last-child {
    display: ${(props) => (props.click ? "none" : "inline-block")};
    padding-top: 1rem;
  }
`;

const BGCenter = styled.button`
  position: relative;
  left: ${(props) => (props.click ? "92%" : "50%")};
  transform: translate(-50%, -50%);
  background-color: transparent;
  display: flex;
  cursor: auto;
  }

  & > :last-child {
    display: ${(props) => (props.click ? "none" : "inline-block")};
    padding-top: 1rem;
  }
`;
const LogoCenter = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 4rem;
  `;

const DarkDiv = styled.div`
  position: absolute;
  background-color: #1E1E1E;
  bottom: 0;
  right: 50%;
  width: ${(props) => (props.click ? "50%" : "0%")};
  height: ${(props) => (props.click ? "100%" : "0%")};
  z-index: 1;
  transition: height 0.5s ease, width 1s ease 0.5s;
  opacity: 50%;

  @media (max-width: 420px) {
  width: ${(props) => (props.click ? "50%" : "0%")};
  right: 75%;
  }
`;

const Main = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <MainContainer>
      <DarkDiv click={click} />
      <Container>
        <SocialIcons theme={click ? "light" : "dark"} />

        <LogoCenter>
        <Img className="logotext" src={LogoText} alt="logo text" />
        </LogoCenter>

        <BGCenter className="lg:top-[35rem] xl:top-[20rem] sm:top-[35vh] top-[14rem]">
        <img className="pic" src={BG}alt="Background" />
        </BGCenter>

        <Center click={click}>
          <LogoNanno
            onClick={() => handleClick()}
            width={click ? 60 : 100}
            height={click ? 60 : 100}
            fill="currentColor"
          />
          <span></span>
        </Center>

        <Contact to="/contact">
          <motion.h2
            initial={{
              y: -200,
              transition: { type: "spring", duration: 1.5, delay: 1 },
            }}
            animate={{
              y: 0,
              transition: { type: "spring", duration: 1.5, delay: 0.5 },
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            CONTACT
          </motion.h2>
        </Contact>

        <WORK to="/portfolio" click={click}>
          <motion.h2
            initial={{
              y: -200,
              transition: { type: "spring", duration: 1.5, delay: 1 },
            }}
            animate={{
              y: 0,
              transition: { type: "spring", duration: 1.5, delay: 0.5 },
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            PORTFOLIO
          </motion.h2>
        </WORK>
        <BottomBar>
          <ABOUT to="/about" click={click}>
            <motion.h2
              initial={{
                y: 200,
                transition: { type: "spring", duration: 1.5, delay: 1 },
              }}
              animate={{
                y: 0,
                transition: { type: "spring", duration: 1.5, delay: 0.5 },
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              ABOUT
            </motion.h2>
          </ABOUT>
          <SKILLS to="/skills">
            <motion.h2
              initial={{
                y: 200,
                transition: { type: "spring", duration: 1.5, delay: 1 },
              }}
              animate={{
                y: 0,
                transition: { type: "spring", duration: 1.5, delay: 0.5 },
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              SKILL
            </motion.h2>
          </SKILLS>
        </BottomBar>
      </Container>
      {click ? <Intro click={click} /> : null}
    </MainContainer>
  );
};

export default Main;
