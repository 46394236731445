export const imageCategories = [
    {
        categoryId: 1,
        categoryName: "UI/UX",
    },
    {
        categoryId: 2,
        categoryName: "WEB",
    },
    {
        categoryId: 3,
        categoryName: "DESIGN",
    },
];

export const images = [
    {
        src: "/images//UI/2.jpg",
        width: 300,
        height: 500,
        categoryId: 1,
        caption: "Kita Membantu Sesama",
    },
    {
        src: "/images/UI/3.jpg",
        width: 300,
        height: 500,
        categoryId: 1,
        caption: "Alpha Investment Group",
    },
    {
        src: "/images/UI/SignUp.jpg",
        width: 300,
        height: 500,
        categoryId: 1,
        caption: "Sign Up tyrant app",
    },
    {
        src: "/images/UI/6.jpg",
        width: 300,
        height:500,
        categoryId: 1,
        caption: "Music UI",
    },
    {
        src: "/images/UI/5.jpg",
        width: 300,
        height: 500,
        categoryId: 1,
        caption: "Redesign Orign Game Launcher",
    },
    {
        src: "/images/UI/pindad.jpg",
        width: 300,
        height: 500,
        categoryId: 1,
        caption: "Redesign Pindad",
    },
    {
        src: "/images/UI/Plagas.jpg",
        width: 300,
        height: 500,
        categoryId: 1,
        caption: "Plagas Top-up Game Store",
    },
    {
        src: "/images/UI/DesignSystem.jpg",
        width: 300,
        height: 500,
        categoryId: 1,
        caption: "Design System",
    },
    {
        src: "/images/UI/8.png",
        width: 300,
        height: 500,
        categoryId: 1,
        caption: "Book Cart",
    },
    {
        src: "/images/UI/7.jpg",
        width: 300,
        height: 500,
        categoryId: 1,
        caption: "Sign In & Sign Up",
    },
    {
        src: "/images/UI/1.jpg",
        width: 300,
        height: 500,
        categoryId: 2,
        caption: "Weare GOD GAMES",
    },
    {
        src: "/images/UI/4.jpg",
        width: 300,
        height: 500,
        categoryId: 2,
        caption: "OZBosst",
    },
    {
        src: "/images/Design/sosmed_octarine.jpg",
        width: 300,
        height: 500,
        categoryId: 3,
        caption: "Octarine",
    },
    {
        src: "/images/Design/gudpuszi.jpg",
        width: 300,
        height: 500,
        categoryId: 3,
        caption: "Logo Gudang Pusat Zeni",
    },
    {
        src: "/images/Design/Illustration/AsNodt.jpg",
        width: 300,
        height: 500,
        categoryId: 3,
        caption: "AsNodt",
    },
    {
        src: "/images/Design/Illustration/Ichigo.jpg",
        width: 300,
        height: 500,
        categoryId: 3,
        caption: "Ichigo Vasto Lorde Form",
    },
    {
        src: "/images/Design/Illustration/Mayuri.jpg",
        width: 300,
        height: 500,
        categoryId: 3,
        caption: "Mayuri Kurotshuci",
    },
    {
        src: "/images/Design/Illustration/Nelliel.jpg",
        width: 300,
        height: 500,
        categoryId: 3,
        caption: "Nelliel Tu Odelschwanck",
    },
    {
        src: "/images/Design/Illustration/Serval.jpg",
        width: 300,
        height: 500,
        categoryId: 3,
        caption: "Serval",
    },
    {
        src: "/images/Design/Illustration/ShigekuniYamamoto.jpg",
        width: 300,
        height: 500,
        categoryId: 3,
        caption: "Shigekuni Yamamoto",
    },
    {
        src: "/images/Design/Illustration/Terriermon.jpg",
        width: 300,
        height: 500,
        categoryId: 3,
        caption: "Terriermon",
    },
    {
        src: "/images/Design/Illustration/Terrucemon.jpg",
        width: 300,
        height: 500,
        categoryId: 3,
        caption: "Terriermon and Lucemon",
    },
    {
        src: "/images/Design/Illustration/Victory&UlforceGreymon.jpg",
        width: 300,
        height: 500,
        categoryId: 3,
        caption: "Victory & Ulforce Greymon",
    },
    {
        src: "/images/Design/Illustration/YamiSukehiro.jpg",
        width: 300,
        height: 500,
        categoryId: 3,
        caption: "Yami Sukehiro",
    },
];

// You can use the 'images' array in your application as needed.
