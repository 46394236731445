import {
  SiMicrosoftpowerpoint,
  SiMui,
  SiHtml5,
  SiCss3,
  SiTailwindcss,
  SiCanva,
  SiUbuntu,
  SiFigma,
  SiAdobexd,
  SiAdobeillustrator,
  SiAdobephotoshop,
  SiAdobeaftereffects,
  SiAdobepremierepro,

} from "react-icons/si";
import { FcLinux } from "react-icons/fc";
import { FaRegFileWord } from "react-icons/fa";
import { TbBrandFramerMotion } from "react-icons/tb";
import { RiFileExcel2Line } from "react-icons/ri";
import { MdMovieEdit } from "react-icons/md";

const icons = [
  {
    name: "Ms Powerpoint",
    icon: SiMicrosoftpowerpoint,
    options: {
      color: "#E95420",
      size: "100px",
    },
  },
  {
    name: "Ms Excel",
    icon: RiFileExcel2Line,
    options: {
      color: "#86C85D",
      size: "100px",
    },
  },
  {
    name: "Ms Word",
    icon: FaRegFileWord,
    options: {
      color: "#0984e3",
      size: "100px",
    },
  },
  {
    name: "Figma",
    icon: SiFigma,
    options: {
      color: "#A259FF",
      size: "100px",
    },
  },
  {
    name: "Adobe XD",
    icon: SiAdobexd,
    options: {
      color: "#ff00b2",
      size: "100px",
    },
  },
  {
    name: "Adobe Illustrator",
    icon: SiAdobeillustrator,
    options: {
      color: "#E95420",
      size: "100px",
    },
  },
  {
    name: "Adobe Photoshop",
    icon: SiAdobephotoshop,
    options: {
      color: "#002aff",
      size: "100px",
    },
  },
  {
    name: "Adobe Premiere",
    icon: SiAdobepremierepro,
    options: {
      color: "#9997FE",
      size: "100px",
    },
  },
  {
    name: "Adobe AfterEffect",
    icon: SiAdobeaftereffects,
    options: {
      color: "#D291FF",
      size: "100px",
    },
  },
  {
    name: "Canva",
    icon: SiCanva,
    options: {
      color: "#01C3CF",
      size: "100px",
    },
  },
  
  {
    name: "Capcut",
    icon: MdMovieEdit,
    options: {
      color: "#fff",
      size: "100px",
    },
  },

  {
    name: "HTML",
    icon: SiHtml5,
    options: {
      color: "#E34C26",
      size: "100px",
    },
  },
  {
    name: "CSS",
    icon: SiCss3,
    options: {
      color: "#2965f1",
      size: "100px",
    },
  },
  {
    name: "Material UI",
    icon: SiMui,
    options: {
      color: "#007FFF",
      size: "100px",
    },
  },
  {
    name: "Framer Motion",
    icon: TbBrandFramerMotion,
    options: {
      color: "#fff",
      size: "100px",
    },
  },
  {
    name: "Tailwind",
    icon: SiTailwindcss,
    options: {
      color: "#61DAFB",
      size: "100px",
    },
  },
  {
    name: "Linux",
    icon: FcLinux,
    options: {
      color: "#000000",
      size: "100px",
    },
  },
  {
    name: "Ubuntu",
    icon: SiUbuntu,
    options: {
      color: "#E95420",
      size: "100px",
    },
  },
  // {
  //   name: "Google Cloud Platform",
  //   icon: SiGooglecloud,
  //   options: {
  //     color: "#4285F4",
  //     size: "100px",
  //   },
  // },

];

export default icons;
