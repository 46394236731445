import React from "react";
import styled, { ThemeProvider} from "styled-components";
import { DarkTheme} from "./Themes";
import SocialIcons from "../subComponents/SocialIcons";
import PowerButton from "../subComponents/PowerButton";
import ParticleComponent from "../subComponents/ParticleComponent";
import BigTitle from "../subComponents/BigTitle";
import icons from "../subComponents/icons";
import "../../src/skills.css";
import { motion } from "framer-motion";
import { fadeIn} from '../utils/motion';

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const Main = styled.div`
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.body};
  padding: 2rem;
  z-index: 999;
  line-height: 1.5;
  cursor: pointer;
  font-family: "Ubuntu Mono", monospace;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 8px;

`;


const Description = styled.div`
  color: ${(props) => props.theme.text};
  padding: 0.5rem 0;
  }

  strong {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  ul,
  p {
    margin-left: 2rem;
  }
`; 

const MySkillsPage = () => {

  const IconItem = (props) => {
    return (
      <div className="skill_item" draggable={true}>
        {React.createElement(props.icon, props.options)}
        <div className="skill_description">
          <h2>{props.name}</h2>
        </div>
      </div>
    );
  };

  return (
    // <>
    <ThemeProvider theme={DarkTheme}>
      <div className="title" style={{ backgroundColor: DarkTheme.body }}/>
      <div className="skills bg-[#070B00]">
        <div className="skill_icons_section">
          {icons.map(
            (icons, index) =>
              icons.icon && (
                <IconItem
                  key={index}
                  icon={icons.icon}
                  options={icons.options}
                  name={icons.name}
                ></IconItem>
              )
          )}
        </div>
      </div>
      <Box>
        <SocialIcons theme="light" />
        <PowerButton />
        <ParticleComponent theme="dark" />
        <motion.div
        className="z-10"
        initial={{ scale: 0 }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 0.5 }}
        variants={ fadeIn('up','spring', 0.2, 1) }
        >
        <Main className="
        xl:h-[50vh] 
        tb:h-[55vh]
        md:h-[50vh] 
        sm:h-[37vh] 
        vsm:h-[50vh] 
        sm:w-[35vh] 
        vsm:w-[34vh] 
        xsm:w-[32vh]
        xl:w-[60vh] 
        h-[50vh] 
        w-[40vh]
        border-[1px] 
      border-white 
        border-opacity-[10%] 
        mb-[20rem] 
        xl:mt-[20rem] 
        vsm:mt-[-2rem] 
        mt-[10rem]">
          <div className="lg:text-[35px] tb:text-[35px] text-[25px]">
            Designer
          </div>
          <Description className="lg:text-[20px] tb:text-[25px] text-[15px]" >
            I love to create design which speaks, Keep it clean, minimal and
            simple.
          </Description>
          <Description className="lg:text-[22px] tb:text-[25px] text-[15px]">
            <strong>I like to Design</strong>
            <ul className="lg:text-[20px] tb:text-[25px] text-[15px]">
              <li>Web Pages</li>
              <li>Mobile Apps</li>
            </ul>
          </Description>
          <Description className="lg:text-[22px] tb:text-[25px] text-[15px]">
            <strong>Tools</strong>
            <ul className="lg:text-[20px] tb:text-[25px] text-[15px]">
              <li>Figma, Canva</li>
            </ul>
          </Description>
        </Main>
        </motion.div>

        <BigTitle text="SKILLS" top="10%" right="10%" />
      </Box>
    </ThemeProvider>
    // </>
  );
};

export default MySkillsPage;
