// Home button
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Powerclose } from "../components/AllSvgs";

const Power = styled.button`
  border-color: #fff;
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #070B00;
  padding: 0.3rem;
  border-radius: 50%;
  border: 3px;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  cursor: pointer;

  }

  & > *:first-child {
    text-decoration: none;
    color: inherit;
  }
`;

const PowerButton = () => {
  return (
    <Power>
      <NavLink to="/">
        <Powerclose width={50} height={50} />
      </NavLink>
    </Power>
  );
};

export default PowerButton;
