import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "normalize.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


const styles = {
  innerWidth: '2xl:max-w-[1280px] w-full',
  interWidth: 'lg:w-[80%] w-[100%]',

  paddings: 'sm:p-16 xs:p-8 px-6 py-12',
  yPaddings: 'sm:py-16 xs:py-8 py-12',
  xPaddings: 'sm:px-16 px-6',
  topPaddings: 'sm:pt-16 xs:pt-8 pt-12',
  bottomPaddings: 'sm:pb-16 xs:pb-8 pb-12',

  flexCenter: 'flex justify-center items-center',
  flexStart: 'flex justify-start items-start',
  flexEnd: 'flex justify-end',
  navPadding: 'pt-[98px]',

  // hero section
  headingSmall:
      'lg:mt-64 mt-36 font-bold lg:text-[16px] md:text-[16px] text-[16px] lg:leading-[50.4px] md:leading-[80.4px] sm:leading-[50.4px] text-center leading-[30.4px] pr-8 uppercase text-[#F4FBFF]',
  heroHeading:
      'font-extrabold lg:text-[75px] md:text-[45px] sm:text-[40px] text-[40px] lg:leading-[50.4px] md:leading-[114.4px] text-center sm:leading-[74.4px] leading-[64.4px] pr-8 uppercase text-[#F4FBFF]',
  subHeading:
      'font-medium lg:text-[18px] md:text-[18px] sm:text-[18px] text-[10px] lg:leading-[60px] md:leading-[80.4px] sm:leading-[50.4px] text-center leading-[30.4px] pr-8  text-[#616467] uppercase',
  descHeading2:
      'mt-0.5 lg:mt-3 font-medium lg:text-[16px] sm:text-[18px] text-[12px] text-center pr-8 uppercase text-[#505559]',
  heroDText:
      'md:w-[212px] sm:w-[80px] w-[60px] md:h-[100px] sm:h-[40px] h-[38x] md:border-[18px] border-[9px] rounded-r-[50px] border-white sm:mx-2 mx-[6px]',
};

export default styles;