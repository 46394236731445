import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Me from "../assets/Images/vector.png";

const Box = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65vw;
  height: 55vh;
  display: flex;

  background: linear-gradient(
        to right,
        ${(props) => props.theme.body} 50%
      )
      top;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  border-left: 5px solid ;
  color: #ACEB2F;
  z-index: 1;

  @media (max-width: 420px) {
    border-left: 5px solid ;
    left: 60%;
    width: 70vw;
    }
`;

const SubBox = styled.div`
  width: 50%;
  position: relative;
  display: flex;
`;

const Text = styled.div`
  font-size: 2em;
  color: ${(props) => props.theme.body};
  padding: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  & > *:last-child {
    color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
    font-size: 0.5em;
    font-weight: 100;
  }
`;

const Intro = () => {
  return (
    <Box
      initial={{ height: 0 }}
      animate={{ height: "15rem" }}
      transition={{ type: "spring", duration: 2, delay: 1 }}
    >
      <SubBox>
        <Text>
          <h1 className="md:text-[2.5rem]">Hallo,</h1>
          <h5>Saya Rano Indradinata</h5>
          <h7>DESAIN UI/UX DAN GRAFIS, SIAP UNTUK MENGUBAH IDE-IDE ANDA MENJADI PENGALAMAN DIGITAL YANG MENGAGUMKAN.</h7>
        </Text>
      </SubBox>
      <SubBox>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 2 }}
        >
          <img className="lg:relative lg:bottom-0 xl:top-[-100%] lg:top-[-10%] lg:w-[100%] tb:w-[100%] w-[0px] lg:h-auto" src={Me} alt="Profile Pic" />
        </motion.div>
      </SubBox>
    </Box>
  );
};

export default Intro;
