// Here we will create dark and light theme variables
export const lightTheme = {
  body: "#FCF6F4",
  text: "#000000",
  fontFamily: "'Source Sans Pro', sans-serif",
  bodyRgba: "252, 246, 244",
  textRgba: "0,0,0",
};

export const DarkTheme = {
  body: "#070B00",
  text: "#FCF6F4",
  fontFamily: "'Source Sans Pro', sans-serif",
  textRgba: "252, 246, 244",
  bodyRgba: "0,0,0",
};
  
export const GreenTheme = {
  body: "#ACEB2F",
  text: "#fff",
  fontFamily: "'Source Sans Pro', sans-serif",
  textRgba: "252, 246, 244",
  bodyRgba: "0,0,0",
};
