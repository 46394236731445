import React from "react";
import LightGallery from "lightgallery/react";
import PowerButton from "../subComponents/PowerButton";
import "../../src/gallery.css";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-autoplay.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-share.css";
import "lightgallery/css/lg-rotate.css";

import lgThumbnail from "lightgallery/plugins/thumbnail";
import { useState } from "react";

export function Gallery({ images, imageCategories}) {
    const [displayImages, setDisplayImages] = useState(images);
    const handleFilter = (categoryId) => {
        if (categoryId > 0) {
            setDisplayImages(
                images.filter(function (image) {
                    return image.categoryId === categoryId;
                })
            );
        } else {
            setDisplayImages(images);
        }
    };
    return (
        <div className="App mt-[10%]">
            <PowerButton />
            <div className="filtrs">
                <div className="filtergap">
                <span
                    className="badge badge--info"
                    onClick={() => handleFilter("")}
                >
                    All
                </span>
                {imageCategories &&
                    imageCategories.map((category) => {
                        return (
                            <span
                                key={category.categoryId}
                                className="badge badge--info"
                                onClick={() =>
                                    handleFilter(category.categoryId)
                                }
                            >
                                {category.categoryName}
                            </span>
                        );
                    })}
                </div>
            </div>
            
            <LightGallery download={false} speed={500} plugins={[lgThumbnail]}>
                {displayImages.map((image, index) => {
                    return (
                        <a href={image.src} key={index}>
                            <div className="relative grid gap-2 grid-cols-1 pb-10 hover:scale-[102%] bg-[#070B00] transition-transform">
                                <img className="imgdisplay" alt={image.caption} src={image.src} />
                                <h5 className="text-[15px] font-semibold text-[#CCCCCC]">{image.caption}</h5>
                            </div>
                        </a>
                    );
                })}
            </LightGallery>
        </div>
    );
}
