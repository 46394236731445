import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "./components/Themes";
import { AnimatePresence } from "framer-motion";
import GlobalStyle from "./globalStyles";
import { Gallery } from "./components/Gallery";
import { imageCategories, images } from "./mocks/images";

// Components
import Main from "./components/Main";
import AboutPage from "./components/AboutPage";
import ContactPage from "./components/ContactPage";
import MySkillsPage from "./components/MySkillsPage";

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <Router>
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/skills" element={<MySkillsPage />} />
            <Route path="/portfolio" element={<Gallery images={images} imageCategories={imageCategories}/>} />
            <Route path="*" element={<Main />} />
          </Routes>
        </AnimatePresence>
      </Router>
    </ThemeProvider>
  );
}

export default App;
