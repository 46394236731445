import React from "react";
import styled, { ThemeProvider } from "styled-components";
import LogoComponent from "../subComponents/LogoComponent";
import SocialIcons from "../subComponents/SocialIcons";
import PowerButton from "../subComponents/PowerButton";
import BigTitle from "../subComponents/BigTitle";
import { lightTheme } from "./Themes";
import {
  Flex,
  Box,
  Heading,
  Button,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { MdEmail, MdLocationOn,} from "react-icons/md";
import MapComponent from "./MapComponent";

const MainBox = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
`;

const ContactForm = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContactFormContainer = styled.div`
  background: rgba(32, 32, 32, 0.17);
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 1);
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.120);
  margin: 10rem;
  padding: 3rem;

  @media (max-width: 1024px) {
    padding: 1rem;
    }

  @media (max-width: 912px) {
    padding: 1rem;
    border-radius: 15px;
    }

  @media (max-width: 820px) {
    padding: 1rem;
    border-radius: 15px;
    }

  @media (max-width: 768px) {
    padding: 1rem;
    border-radius: 15px;
    }
  @media (max-width: 768px) {
    padding: 1rem;
    border-radius: 15px;
    }  

  @media (max-width: 430px) {
    padding: 1rem;
    border-radius: 15px;
    }

  @media (max-width: 414px) {
    padding: 1rem;
    border-radius: 15px;
    }
  @media (max-width: 390px) {
    padding: 1rem;
    border-radius: 15px;
    }
  
  @media (max-width: 375px) {
    padding: 1rem;
    border-radius: 15px;
    }
    
  @media (max-width: 360px) {
    padding: 1rem;
    border-radius: 15px;
    }
  
  @media (max-width: 280px) {
    padding: 1rem;
    border-radius: 15px;
  }
`;

const Container = styled.div`
  background-color: #070B00;
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 5rem;
  maxW="container.xl"
  mt={0}
  centerContent
  overflow="hidden"
  z-index= 1;
`;

const ContactPage = () => {

  return (
    <ThemeProvider theme={lightTheme}>
      <MainBox>
        <Container>
          <LogoComponent theme="light" />
          <SocialIcons theme="light" />

          <BigTitle text="CONTACT" top="10%" left="8%" />
          <PowerButton />

          <div>
            <ContactForm>
              <ContactFormContainer>
                <form>
                  <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
                    <Flex>
                      <WrapItem padding="0 1rem">
                        <Box>
                          <Heading className=" text-[#ACEB2F] text-[1.5rem]">Contact</Heading>
                          <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                            <VStack pl={0} spacing={3} alignItems="flex-start">
                            <div className="flex items-center justify-between gap-1">
                              <Button 
                                height="50px"
                                variant="ghost"
                                color="#fff"
                                leftIcon={
                                  <MdEmail color="#fff" size="20px" />
                                }
                              />
                               <span className="text-[#fff] "> ranoindra@hotmail.com </span>
                            </div>
                            <div className="flex items-center justify-between gap-3">
                              <Button
                                height="50px"
                                variant="ghost"
                                background="none !important"
                                justifyContent="flex-start"
                                border="none"
                                color="#fff"
                                leftIcon={
                                  <MdLocationOn color="#fff" size="20px" />
                                }
                              >
                                <span className="text-[#fff]">Jawa Barat, Indonesia</span>
                              </Button>
                             </div> 
                                <div className="flex items-center justify-between flex-wrap gap-5">
                                <h4 className="font-bold md:text-[64px] text-[44px] text-[#F4FBFF]">
                                  Hire Me
                                </h4>
                                <a href="mailto:ranoindra@hotmail.com">
                                  <button type="button" className="flex items-center bg-[#ACEB2F] hover:bg-[#5b712f] rounded-[10px] gap-[1px]">
                                    <img src="/email.png" alt="button hire" className="w-[64px] h-[64px] object-contain py-5"/>
                                    <span className="font-bold text-[16px] text-[#070B00] h-auto w-[70px]">Email</span>
                                  </button> 
                                </a>
                              </div>
                            </VStack>
                          </Box>
                        </Box>
                      </WrapItem>
                      <WrapItem padding="0 1rem">
                        
                      </WrapItem>
                    </Flex>
                  </Wrap>
                </form>
              </ContactFormContainer>
            </ContactForm>

            <div
              style={{
                display: "block",
                margin: "0 auto",
                width: "50%",
              }}
            >
              <MapComponent />
            </div>
          </div>
        </Container>
      </MainBox>
    </ThemeProvider>
  );
};

export default ContactPage;
