import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { DarkTheme } from "./Themes";
import LogoComponent from "../subComponents/LogoComponent";
import SocialIcons from "../subComponents/SocialIcons";
import PowerButton from "../subComponents/PowerButton";
import ParticleComponent from "../subComponents/ParticleComponent";
import BigTitle from "../subComponents/BigTitle";
import "../../src/about.css";
import { motion } from "framer-motion";
import {  fadeIn} from '../utils/motion';

const Box = styled.div`
  overflow: hidden;
  background-color: #070B00;
  width: 100vw;
  position: relative;
`;


const AboutPage = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Box>
        <LogoComponent theme="dark" />
        <SocialIcons theme="dark" />
        <PowerButton />
        <ParticleComponent theme="dark" />
        <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 0.5 }}
        variants={ fadeIn('up','tween', 0.2, 1) }
        className="
        flex-[7] 
        lg:w-[800px]
        flex 
        justify-end 
        flex-col 
        backdrop-blur-md 
        mt-[35%]
        xl:mt-[10%] 
        lg:mt-[35%]
        md:mt-[22%]
        vsm:mt-[30%]
        xsm:mt-[32%] 
        vsm:ml-[5%]
        vsm:mr-[5%]
        sm:p-8 
        p-10 
        background-opacity-[70%] 
        rounded-[22px] 
        border-[2px] 
      border-white 
        border-opacity-[10%] 
        relative 
        lg:m-auto 
        md:m-[5%] 
        z-10"
        >
          <div>
            <h4 className="font-bold sm:text-[32px] text-[26px] sm:leading-[40px] leading-[36px] text-[#F4FBFF]">
              Rano Indradinata
            </h4>
          </div>
          <p className="mt-[2px] font-normal sm:text-[20px] text-[14px] sm:leading-[45px] leading-[39px] text-[#616467]">
          Saya seorang Sarjana Ilmu Komputer, berpengalaman dibidang UI/UX dan Desain Grafis. Saya tertarik dengan dunia desain sejak lama,
          saya terus mengembangkan kemampuan saya hingga saat ini dan memiliki beberapa kemampuan Microsoft Office, Editing Video, IT Support troubleshooting hardware atau software dan mengoperasikan beberapa Sistem Operasi.
          Disiplin, Jujur dan bertanggung jawab selalu saya utamakan dalam bekerja serta mampu bekerja secara individu maupun dalam tim. Memiliki semangat untuk berkembang,
          berdedikasi tinggi, memiliki motivasi tinggi, mudah beradaptasi, serta tertarik dengan hal baru.
          </p>
      </motion.div>

        <BigTitle text="ABOUT" top="10%" left="5%"/>
      </Box>
    </ThemeProvider>
  );
};

export default AboutPage;
